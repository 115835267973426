var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.billingDetail.order_items.image_url ? _vm.apiFile + _vm.billingDetail.order_items.image_url : require('@/assets/images/elp/empty.png')
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_c('h2', [_vm._v(_vm._s(_vm.billingDetail.order_items.name))]), _vm.billingDetail.status == 'PAID' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "success"
    }
  }, [_vm._v(" Sudah Bayar ")]) : _vm._e(), _vm.billingDetail.status == 'UNPAID' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "info"
    }
  }, [_vm._v(" Menunggu ")]) : _vm._e(), _vm.billingDetail.status == 'FAILED' || _vm.billingDetail.status == 'EXPIRED' || _vm.billingDetail.status == 'REFUND' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "danger"
    }
  }, [_vm._v(" Dibatalkan ")]) : _vm._e()], 1), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "3"
    }
  }, [_c('span', [_vm._v(" No. Invoice")]), _vm._v(" "), _c('br'), _c('b', [_vm._v(_vm._s(_vm.billingDetail.merchant_ref))])]), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "3"
    }
  }, [_c('span', [_vm._v("Tanggal Transaksi")]), _vm._v(" "), _c('br'), _c('b', [_vm._v(_vm._s(_vm.humanDate(_vm.billingDetail.created_at)))])]), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary ",
      "to": {
        name: 'billing-detail-invoice'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DownloadIcon"
    }
  }), _vm._v(" Lihat Invoice")], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }